<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm yếu tố gây chấn thương'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFile"
      @clickExportExcel="downloadExportFile"
      @importFile="importFileExcel"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            variant="light-primary"
          >
            <span>{{ props.row.code }}</span>
          </b-badge>
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">

          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <edit-element
      :id="idModal"
      :dataDetai="dataDetai"
      :title="'Thêm yếu tố gây chấn thương'"
      @accept="handleAccept"
    />

  </div>
</template>

<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import EditElement from './components/EditElement.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    ConfirmModal,
    FeatherIcon,
    BBadge,
    EditElement,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      arrayExcel: [
        'code',
        'name',
      ],
      idModal: 'modal-create',
      dataList: [],
      showBtnMultiDelete: false,
      urlQuery: {
        pageNumber: 1,
        pageSize: 10,
        key: '',
      },
      columns: [
        {
          label: 'MÃ YẾU TỔ CHẤN THƯƠNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN YẾU TỔ CHẤN THƯƠNG',
          field: 'name',
          sortable: false,
        },

        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      totalRecord: 0,
      idDetail: null,
      dataDetai: {},
    }
  },

  created() {
    this.getList()
  },

  methods: {
    ...mapActions('injuryElement', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),
    handleAccept(val) {
      if (!this.idDetail) {
        this.addInjury(val)
      } else {
        this.editInjury(val)
      }
    },

    addInjury(val) {
      axiosApiInstance.post(ConstantsApi.CREATE, val).then(response => {
        this.$bvToast.toast('Thêm yếu tố chấn thương thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.idModal)
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    editInjury(val) {
      const payload = {
        ...val,
        id: this.idDetail,
      }
      axiosApiInstance.put(ConstantsApi.EDIT, payload).then(response => {
        this.$bvToast.toast('chỉnh sửa yếu tố chấn thương thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.idModal)
      }).catch(e => {
        this.$bvToast.toast('Có lỗi xảy ra', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showModalCreate() {
      this.$bvModal.show(this.idModal)
    },
    async showModalEdit(id) {
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL}${id}`)
      this.dataDetai = data
      this.$bvModal.show(this.idModal)
    },

    async getList() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getList()
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.getList()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.getList()
      }
    },

    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-injury-element' })
    },

  },
}
</script>

<style>

</style>
