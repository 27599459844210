var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm yếu tố gây chấn thương',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"clickDelete":_vm.deleteItems,"clickDowloadSample":_vm.downloadExampleFile,"clickExportExcel":_vm.downloadExportFile,"importFile":_vm.importFileExcel,"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(props.row.code))])])],1):(props.column.field === 'ActionFunction')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('edit-element',{attrs:{"id":_vm.idModal,"dataDetai":_vm.dataDetai,"title":'Thêm yếu tố gây chấn thương'},on:{"accept":_vm.handleAccept}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }