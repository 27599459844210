<template>
  <b-modal
    :id="id"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    cancel-title="Hủy bỏ"
    centered
    no-close-on-backdrop
    :title="title"
    @ok="accept($event)"
    @hidden="resetData"
  >
    <validation-observer ref="rule">
      <b-form>
        <b-form-group>
          <label>Mã yếu tố chấn thương<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required|alpha-dash"
            :custom-messages="{required: 'Mã yếu tố chấn thương là bắt buộc'}"
          >
            <b-form-input
              v-model="dataInput.code"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <label>Tên yếu tố chấn thương<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="{required: 'Tên yếu tố chấn thương là bắt buộc'}"
          >
            <b-form-input
              v-model="dataInput.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import { required, alphaDash } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
  },

  props: {
    id: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    dataDetai: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      required,
      dataInput: {
        name: '',
        code: '',
      },
    }
  },

  watch: {
    dataDetai(val) {
      if (val) {
        this.dataInput = {
          name: val.name,
          code: val.code,
        }
      }
    },
  },

  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    resetData() {
      this.dataInput = {
        name: '',
        code: '',
      }
    },

  },
}
</script>

<style>

</style>
